import { Component } from "react";
import "./Intro.css";
import "./IntroMobile.css";
import memoji from "./img/memoji.png";
import logo from "./img/logo-small.png";
import cv from "./img/cv.png";
class Intro extends Component {
  constructor(props) {
    super();
    this.state = {
        isMobile: props.isMobile
    };
  }

    render() {
    if (this.state.isMobile()) {
      return ( 
        <div className="intro-mobile home-section">
          <div className="intro-memoji-mobile">
            <img className="intro-image-mobile" src={memoji} alt="memoji" />
            <div className="intro-cv-mobile">
              <a href="https://cv.noursandid.com">
                <img
                  className="intro-cv-box-icon-mobile"
                  alt="download"
                  src={cv}
                />
                <p className="intro-cv-box-button-mobile">CV</p>
              </a>
            </div>
          </div>
          <div className="intro-info-mobile">
            <img className="intro-logo-mobile" src={logo} alt="logo" />
            <div className="intro-details-mobile">
              <p className="intro-title-mobile">Nour Sandid</p>
              <p className="intro-subtitle-mobile">iOS Engineer </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="intro home-section">
          <div className="intro-info">
            <img className="intro-logo" src={logo} alt="logo" />
            <div className="intro-details">
              <p className="intro-title">Nour Sandid</p>
              <p className="intro-subtitle">iOS Engineer </p>
            </div>
          </div>
          <div className="intro-memoji">
            <img className="intro-image" src={memoji} alt="memoji" />
            <div className="intro-cv">
              <a href="https://cv.noursandid.com">
                <img className="intro-cv-box-icon" alt="download" src={cv} />
                <p className="intro-cv-box-button">CV</p>
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Intro;
